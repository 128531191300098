import React from 'react';
import ProductResults from './../../components/ProductResults';

const Search = ({ }) => {
  return (
    <div className="searchPage">
      <ProductResults />
    </div>
  );
};

export default Search;