import React from 'react';
import Directory_NoCTA from './../../components/Directory_NoCTA'; 
import './styles.scss';

const Homepage = props => {
    return (
        <section className="homepage">
            <Directory_NoCTA />
        </section>
    );
};

export default Homepage; 