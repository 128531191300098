import React from 'react';
import './styles.scss';

const Footer = props => {
    return (
        <footer className="footer">
            <div className="wrap">
                <p>That which we give makes us richer, that which is hoarded is lost... ― Shota Rustaveli, The Knight in the Panther's Skin</p>
            </div>
        </footer>
    );
}

export default Footer; 
