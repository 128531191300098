import React from 'react';
import { Link } from 'react-router-dom';
import ShopMen from './../../assets/shopMens.png';
import ShopWomen from './../../assets/shopWomens.png';
import blackBackground from './../../assets/khelovani-logo-black-background.png';
import './styles.scss';

const Directory_NoCTA = props => {
  return (
    <div className="directory_nocta">
      <div className="wrap">
        <div
          className="main_item"
          style={{
            backgroundImage: `url(${blackBackground})`
          }}
        >
          <Link to="/">
            More Coming Soon...
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Directory_NoCTA;