import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useMavigate, Link } from 'react-router-dom';
/*import { resetPasswordStart, resetUserState } from './../../redux/User/user.actions';*/
import './styles.scss';

import AuthWrapper from './../AuthWrapper';
import FormInput from './../forms/FormInput';
import Button from './../forms/Button';

const initialState = {
  email: '',
  errors: []
};

const EmailPassword = () => {
  const [state, setState] = useState({ ...initialState });
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
      errors: [],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
        console.log('Attempting to send password reset email...');
      const { email } = state;

      const config = {
        // replace with actual login page once it's published
        url: 'https://localhost:3000/login',
      };
      //updated, not found in the tutorial
      navigate('/login');
      console.log('Password Reset');
    

    } catch (err) {
      console.log('Something went wrong', err);

      // Include error handling logic similar to the tutorial
      const errMessage = err.message || 'Something went wrong';
      setState({
          ...state,
          errors: [errMessage],
      });
    }
  };

  const configAuthWrapper = {
    headline: 'Email Password'
  };

  return (
    <AuthWrapper {...configAuthWrapper}>
      <div className="formWrap">
      {state.errors.length > 0 && (
          <ul>
            {state.errors.map((e, index) => (
              <li key={index}>{e}</li>
            ))}
          </ul>
        )}
        <form onSubmit={handleSubmit}>
          <FormInput
            type="email"
            name="email"
            value={state.email}
            placeholder="Email"
            onChange={handleChange}
          />
          <Button type="submit">
            Email Password
          </Button>
        </form>
      </div>
    </AuthWrapper>
  );
};

export default EmailPassword;
