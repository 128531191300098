import React from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { Link } from 'react-router-dom';
import { checkUserIsAdmin } from './../../Utils';
import './styles.scss';

const selectUser = state => state.user;

const mapState = createSelector(
  [selectUser],
  (user) => ({
    currentUser: user.currentUser
  })
);

const AdminToolbar = props => {
  const { currentUser } = useSelector(mapState);

  const isAdmin = checkUserIsAdmin(currentUser);
  if (!isAdmin) return null;

  return (
    <div className="adminToolbar">
      <ul>
        <li>
          <Link to="/admin">
            My admin
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default AdminToolbar;