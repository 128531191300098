export const existingCartItem = ({
    prevCartItems,
    nextCartItem
  }) => {
    return prevCartItems.find(
      cartItem => cartItem.documentID === nextCartItem.documentID
    );
  };
  
  // cart.utils.js

export const handleAddToCart = ({ prevCartItems, nextCartItem }) => {
  // Find the item in the cart
  const existingCartItem = prevCartItems.find(
    cartItem => cartItem.documentID === nextCartItem.documentID
  );

  // If the item exists, increase the quantity
  if (existingCartItem) {
    return prevCartItems.map(cartItem =>
      cartItem.documentID === nextCartItem.documentID
        ? { ...cartItem, quantity: cartItem.quantity + 1 }
        : cartItem
    );
  }

  // If the item does not exist, add it to the cart
  return [...prevCartItems, { ...nextCartItem, quantity: 1 }];
};

export const handleRemoveCartItem = ({ prevCartItems, cartItemToRemove }) => {
  return prevCartItems.filter(item => item.documentID !== cartItemToRemove.documentID);
};

export const handleReduceCartItem = ({ prevCartItems, cartItemToReduce }) => {
  const existingCartItem = prevCartItems.find(
    cartItem => cartItem.documentID === cartItemToReduce.documentID
  );

  // If the item's quantity is 1, then remove the item
  if (existingCartItem.quantity === 1) {
    return prevCartItems.filter(
      cartItem => cartItem.documentID !== existingCartItem.documentID
    );
  }

  // Otherwise, reduce the item's quantity
  return prevCartItems.map(cartItem =>
    cartItem.documentID === existingCartItem.documentID
      ? { ...cartItem, quantity: cartItem.quantity - 1 }
      : cartItem
  );
};