import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { signOutUserStart } from './../../redux/User/user.actions';
import { createSelector } from 'reselect';
import { selectCartItemsCount } from './../../redux/Cart/cart.selectors';
import './styles.scss';

import { auth } from './../../firebase/utils';

import Logo from './../../assets/khelovani-logo-black-background.png'
import logo_vect from './../../assets/logo_vect.png'

const selectUser = state => state.user;

const mapState = createSelector(
  [selectUser, selectCartItemsCount],
  (user, numOfCartItems) => ({
    currentUser: user.currentUser,
    totalNumCartItems: numOfCartItems
  })
);
  

const Header = () => {
    const location = useLocation();
    const [activeMenu, setActiveMenu] = useState(false);
    const dispatch = useDispatch();
    const { currentUser, totalNumCartItems } = useSelector(mapState);

    const signOut = () => {
        dispatch(signOutUserStart());
      };
     
      useEffect(() => {
        setActiveMenu(false);
      }, [location]);

    return (
        <header className="header">
            <div className="wrap">
                <div className="logo">
                <Link to="/"><img src={logo_vect} alt="Khelovani Logo" />
                    </Link>
                </div>

                <nav className={`mainMenu ${activeMenu ? 'active' : ''}`}>
                    <ul>
                    <li>
                        <Link to="/">
                        Home
                        </Link>
                    </li>
                    <li>
                        <Link to="/search">
                        Search
                        </Link>
                    </li>
                    </ul>
                </nav>

            <div className="callToActions">
                
            <ul>

                <li>
                <Link to="/cart">
                    Your Cart ({totalNumCartItems})
                    <i className="fas fa-shopping-basket"></i>
                </Link>
                </li>

                {currentUser && [
              <li key={1}>
                <Link to="/dashboard">
                  My Account
                  <i className="fas fa-user-circle"></i>
                </Link>
              </li>,
              <li key={2}>
                <span onClick={() => signOut()}>
                  LogOut
                  <i className="fas fa-sign-out-alt"></i>
                </span>
              </li>
            ]}

            {!currentUser && [
            <li key={1} className="hideOnMobile">
                <Link to="/registration">
                    Register
                </Link>
                </li>,
            <li key={2}>
                <Link to="/login">
                    Login
                    <i className="fas fa-user-circle"></i>
                </Link>
              </li>
            ]}

            <li className="mobileMenu">
                <span onClick={() => setActiveMenu(!activeMenu)}>
                    <i className="fas fa-bars"></i>
                </span>
            </li>

          </ul>


            </div>
        </div>
            {/* <div className="wrap">
            <h1>Khelovani Imported Goods</h1>    
            </div> */}
        </header>
    );
}; 

export default Header; 